<template>
  <b-dropdown
    ref="dropdown"
    size="sm"
    variant="primary"
    :toggle-class="btnClass"
    :text="btnText"
  >
    <template v-slot:button-content><slot></slot></template>
    <!--begin::Navigation-->
    <div class="navi navi-hover min-w-md-250px">
      <b-dropdown-text tag="div" class="navi-header pb-1">
        <span class="text-primary text-uppercase font-weight-bold"
          >Save Options:</span
        >
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a class="navi-link" href="#" @click.prevent="click('publish')">
          <span class="navi-icon">
            <i class="fas fa-check-circle"></i>
          </span>
          <span class="navi-text">Save as Ready</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a class="navi-link" href="#" @click.prevent="click('draft')">
          <span class="navi-icon">
            <i class="flaticon2-pen"></i>
          </span>
          <span class="navi-text">Save as Draft</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text v-show="showDelete">
        <hr />
      </b-dropdown-text>
      <b-dropdown-text v-show="showDelete" tag="div" class="navi-item">
        <a class="navi-link" href="#" @click.prevent="click('delete')">
          <span class="navi-icon">
            <i class="fas fa-trash"></i>
          </span>
          <span class="navi-text">Delete</span>
        </a>
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<script>
export default {
  name: "SaveMessageDowndown",
  props: {
    btnClass: String,
    btnText: String,
    showDelete: Boolean
  },
  components: {},
  mounted() {},
  methods: {
    click(event) {
      this.$emit(event);
      this.$refs["dropdown"].hide(true);
    }
  }
};
</script>
