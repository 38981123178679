<template>
  <div class="d-flex flex-column align-items-center ">
    <div class="mb-10 text-center">
      <h1 class="font-weight-bolder">{{ messageTitle }}</h1>
      <p class="sub-title">
        You are about to {{ saveMode }} a message. Please fill in all the required fields and hit the
        {{ saveMode }} button.
      </p>
    </div>
    <div class="w-md-75 mb-10">
      <!-- begin: recipients section  -->
      <div class="card card-custom max-w-820px card-border card-border-none mb-7">
        <div class="card-body px-0">
          <div class="d-flex flex-row flex-wrap">
            <!-- begin:  recipients loop -->
            <span class="badge badge-secondary badge-sm my-5 mr-2">{{ form.recipients.length }}</span>
            <div
              :key="recipient.email"
              v-for="recipient in form.recipients"
              role="alert"
              data-toggle="tooltip"
              class="recipient-card alert alert-default m-2 flex-column d-flex justify-content-start alert-dismissible fade show"
            >
              <span
                data-toggle="tooltip"
                :title="recipient.email"
                @click="showRecipientModal(recipient, 'Edit')"
                class="title"
                >{{ recipient.name }}</span
              >
              <button
                type="button"
                class="close text-danger"
                data-dismiss="alert"
                aria-label="Close"
                @click="removeRecipient(recipient)"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <button @click="showRecipientModal({}, 'Add')" class="btn btn-primary btn-sm m-2">
              Add Recipient&nbsp;<i class="fas fa-plus-circle"></i>
            </button>
            <!-- end: recipients loop -->
          </div>
        </div>
      </div>
      <!-- end: recipients section  -->

      <!-- begin:  editor -->
      <div class="card editor-card-wrapper  card-border card-fit card-custom flex-column mb-7">
        <div class="card-header mb-0 px-0 px-sm-10">
          <div class="card-title w-100 mb-0 mt-5">
            <div class="form-group w-100 mb-0">
              <span :class="statusColor" class="label label-inline mr-3">{{ statusLabel }}</span>

              <textarea
                type="text"
                name="messageTitle"
                v-model="form.post_title"
                id="name"
                class="form-control form-control-xl msg-title mt-5"
                placeholder="Type the message title here"
                aria-describedby="prefixId"
              />
            </div>
          </div>
        </div>
        <div class="card-body px-0 px-sm-10">
          <vue-editor
            class="message-editor"
            output="html"
            :editorToolbar="quillToolbar"
            v-model="form.post_content"
          ></vue-editor>
          <small class="mt-5 px-2 form-text "
            >The Place holder: %%name%% will be replaced with the recipient's name. If your message has only one
            recipient, then feel free to remove the placeholder.</small
          >
        </div>
      </div>
      <!-- end editor -->

      <!-- begin:: save buttons -->
      <div class="card card-custom card-shadowless ">
        <div class="card-body px-2">
          <div class="d-flex ">
            <button @click="$router.push({ name: 'messages' })" class="btn-default btn mr-auto">
              <i class="fas fa-arrow-circle-left mr-1"></i> &nbsp;Back
            </button>
            <button
              v-b-tooltip.hover
              title="Delete Message"
              v-show="showDelete"
              @click="deleteMessage()"
              class="btn-hover-light-danger btn mr-0"
            >
              <i class="fas fa-trash pr-0"></i>
            </button>
            <router-link
              v-b-tooltip.hover
              title="Preview Message"
              v-show="showDelete"
              target="_blank"
              :to="{ name: 'messages.view', params: { key: form.ID } }"
              class="btn-hover-light-primary btn mr-2"
            >
              <i class="fas fa-eye pr-0"></i>
            </router-link>
            <SaveMessageDropdown
              :showDelete="false"
              @publish="saveMessage('publish')"
              @draft="saveMessage('draft')"
              @delete="deleteMessage()"
              >{{ saveMessageLabel }}</SaveMessageDropdown
            >
          </div>
        </div>
      </div>
      <!-- end:: save buttons -->
    </div>

    <!-- modals -->
    <b-modal ref="recipientModal" centered @shown="initRecipientValidation()" @ok="handleOk">
      <template #modal-title>{{ currentRecipient.mode }} {{ currentRecipient.name }}</template>
      <template #default>
        <form id="frmRecipient" @submit.stop.prevent="saveRecipient">
          <!-- input: name -->
          <div class="form-group">
            <label class="form-label" for="recipientName">Recipient Name</label>
            <input
              v-model="currentRecipient.name"
              type="text"
              class="form-control"
              name="recipientName"
              id="recipientName"
              aria-describedby="recipientName"
              placeholder="Name"
            />
          </div>
          <!-- end: input Name -->

          <!-- begin : recipient email  -->
          <div class="form-group">
            <label class="form-label" for="recipientEmail">Recipient Email Address</label>
            <input
              v-model="currentRecipient.email"
              type="text"
              class="form-control"
              name="recipientEmail"
              id="recipientEmail"
              aria-describedby="recipientEmail"
              placeholder="Email Address"
            />
          </div>
          <!-- end : recipient email  -->

          <!-- input: phone -->
          <div class="form-group">
            <label class="form-label" for="recipientPhone">Recipient Mobile Number</label>
            <input
              v-model="currentRecipient.phone"
              type="text"
              class="form-control"
              name="recipientPhone"
              id="recipientPhone"
              aria-describedby="recipientPhone"
              placeholder="Mobile Number"
            />
          </div>

          <!-- end: input phone -->
        </form>
      </template>
    </b-modal>
  </div>
</template>
<style scoped lang="scss">
// @import "@vueup/vue-quill/dist/vue-quill.snow.css";

.grow-wrap {
  /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
  display: grid;
}
.grow-wrap::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
.grow-wrap > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
.grow-wrap > textarea,
.grow-wrap::after {
  /* Identical styling required!! */
  border: 1px solid black;
  padding: 0.5rem;
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}

@media (max-width: 500px) {
  .editor-card-wrapper {
    border: none !important;
  }
}

.card-border-none {
  border: none !important;
}

.recipient-card {
  background: #f5f5f5;
  min-width: 200px;
  cursor: pointer;

  .title {
    font-weight: bold;
  }
}

.editor-card-wrapper {
  .form-control {
    padding: 0;
  }

  .ql-container.ql-snow {
  }

  .msg-title {
    border: none;
    font-size: 1.5em;
    line-height: 1.5em;
    font-weight: 800;
    width: 100%;
    max-width: 800;
    margin-bottom: 0;
  }
}

.form-label {
  font-weight: bold;
}
</style>
<script>
// IMPORT MODULES
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_MESSAGE, GET_MESSAGE, DELETE_MESSAGE } from "@/core/services/store/message.module";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";

// IMPORT FORM VALIDATION TOOLS
import FormValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";

import { VueEditor } from "vue2-quill-editor";

// IMPORT PLUGINS
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";

// import components
import SaveMessageDropdown from "./components/SaveMessageDropdown.vue";

// IMPORT {SETUP_TRUSTEES} from "@/core/services/store/messages.module";
export default {
  name: "MessagesEdit",

  components: {
    VueEditor,
    SaveMessageDropdown
  },

  data: function() {
    return {
      currentRecipient: {
        mode: "Add",
        id: 0,
        name: "",
        email: ""
      },
      form: {
        recipients: [],
        post_title: "",
        post_status: "draft",
        post_content: `<p>Dear %%name%%</p><p>Start typing your message here...</p><p>Yours Sincerely</p><p><strong>Your Name</strong></p>`
      }
    };
  },

  computed: {
    ...mapGetters(["currentUser", "buttonSpinner", "apiBusy", "messages", "quillToolbar"]),

    statusColor() {
      return this.form.post_status == "publish" ? "label-light-success" : "label-light-warning";
    },
    statusLabel() {
      return this.form.post_status == "publish" ? "Ready To Send" : "Draft";
    },
    showDelete() {
      return this.$route.name.toLowerCase() == "messages.create" ? false : true;
    },
    saveMessageLabel() {
      return this.apiBusy ? "just a sec..." : "Save Message";
    },

    saveMode() {
      return this.$route.name.toLowerCase() == "messages.create" ? "create" : "edit";
    },
    messageTitle() {
      return this.$route.name == "messages.single" ? "Edit Message" : "Create Message";
    },

    hasRecipients() {
      return this.$route.name.length == 0 ? false : true;
    },

    recipientsCardTitle() {
      return this.form.recipients.length === 1 ? "Recipient" : "Recipients";
    },

    showRecipientsCount() {
      return this.form.recipients.length > 0 ? true : false;
    },

    spinnerClass() {
      return this.apiBusy ? this.buttonSpinner : "";
    }
  },

  watch: {
    saveMode: function(val, oldVal) {
      if (oldVal == "edit" && val == "create") {
        this.form = {
          recipients: [],
          post_title: "",
          post_status: "draft",
          post_content: `<p>Dear %%name%%</p><p>Start typing your message here...</p><p>Yours Sincerely</p><p><strong>Your Name</strong></p>`
        };
      }
    }
  },

  mounted() {
    // set the message break
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Messages", route: "/messages" },
      { title: "Edit", route: "/messages/edit" }
    ]);

    //get data when editing
    if (this.saveMode == "edit") {
      const paramID = this.$route.params.id;
      const message = this.messages.find(item => item.ID == paramID);
      this.form = message ?? this.form;

      // if there's nothing, try loading from the database
      if (!message) {
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

        this.$store
          .dispatch(GET_MESSAGE, paramID)

          .then(response => {
            this.form = response.data;
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          })

          .catch(error => {
            // show toaster
            this.toast(`Error | ${error.message}`);

            // go to messages
            this.$router.push({ name: "messages" });
          });
      }
    }

    // Initialise Auto Save
    this.autoSaver = setInterval(() => {
      console.log("...saving");
      if (this.form.post_title && this.form.recipients.length > 0) this.saveMessage(this.form.post_status);
    }, 60000);
  },

  beforeDestroy() {
    clearInterval(this.autoSaver);
  },

  methods: {
    ...mapActions(["toast"]),

    updateTitle(event) {
      this.form.post_title = event.fromElement.outerText;
    },

    showRecipientModal(recipient, mode) {
      // clear the forms
      if (mode.toLowerCase() == "edit") {
        const now = Date.now();
        recipient.id = now;

        this.currentRecipient = {
          id: recipient.id,
          mode: mode,
          name: recipient.name,
          phone: recipient.phone,
          email: recipient.email
        };
      } else {
        this.currentRecipient = {
          mode: mode,
          name: "",
          phone: "",
          email: ""
        };
      }

      // show the modal
      this.$refs["recipientModal"].show();
    },

    initRecipientValidation() {
      // initialise form validation
      const frmRecipient = KTUtil.getById("frmRecipient");
      const _this = this; //deal with a scoping issue inside the validation callback
      var mode = this.currentRecipient.mode;
      this.valRecipient = FormValidation(frmRecipient, {
        fields: {
          recipientName: {
            validators: {
              notEmpty: {
                message: "Please enter recipient name"
              }
            }
          },
          recipientEmail: {
            validators: {
              emailAddress: {
                message: "Please enter aa valid email address"
              },
              notEmpty: {
                message: "Please enter an email address"
              },
              callback: {
                message: "This email address is already a recipient Enter another one.",
                callback: field => {
                  if (mode.toLowerCase() == "edit") return true;

                  const recipientIndex = _this.form.recipients.findIndex(item => item.email == field.value);
                  if (recipientIndex < 0) {
                    return {
                      valid: true
                    };
                  } else {
                    return {
                      valid: false
                    };
                  }
                }
              }
            }
          },
          recipientPhone: {
            validators: {
              notEmpty: {
                message: "Please enter a phone number"
              },
              callback: {
                message: "This phone number is already a recipient. Enter another one.",
                callback: field => {
                  // ignore validation if we are editing
                  if (mode.toLowerCase() == "edit") return true;

                  const recipientIndex = _this.form.recipients.findIndex(item => item.phone == field.value);
                  if (recipientIndex < 0) {
                    return {
                      valid: true
                    };
                  } else {
                    return {
                      valid: false
                    };
                  }
                }
              }
            }
          }
        },

        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap({})
        }
      });
    },

    handleOk(recipientModal) {
      recipientModal.preventDefault();
      this.saveRecipient();
    },

    getRandomID() {
      return Math.floor(Math.random() * 9999);
    },

    saveRecipient() {
      // do validation
      this.valRecipient.validate().then(status => {
        if (status == "Valid") {
          if (this.currentRecipient.mode == "Add") {
            this.form.recipients.push({
              name: this.currentRecipient.name,
              phone: this.currentRecipient.phone,
              email: this.currentRecipient.email
            });
          } else {
            const recipientIndex = this.form.recipients.findIndex(item => item.id == this.currentRecipient.id);
            this.form.recipients[recipientIndex].name = this.currentRecipient.name;
            this.form.recipients[recipientIndex].phone = this.currentRecipient.phone;
            this.form.recipients[recipientIndex].email = this.currentRecipient.email;
          }

          // close the modal
          this.$refs["recipientModal"].hide();
        }
      });
    },

    getRecipientIndex(recipient) {
      return this.form.recipients.findIndex(item => item.email == recipient.email);
    },

    removeRecipient(recipient) {
      // get index of item in array
      const recipientIndex = this.getRecipientIndex(recipient);

      // remove item from array
      this.form.recipients.splice(recipientIndex, 1);
    },

    saveMessage(status) {
      // do some validation
      if (!this.form.post_title) {
        Swal.fire({
          text: "Please enter the message title",
          icon: "error",
          buttonsStyling: false,
          heightAuto: false,
          showCancelButton: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light-primary",
            cancelButton: "btn font-weight-bold btn-default"
          }
        });
      } else if (this.form.recipients.length < 1) {
        Swal.fire({
          text: "Please add at least one recipient",
          icon: "error",
          buttonsStyling: false,
          heightAuto: false,
          showCancelButton: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn font-weight-bold btn-light-primary",
            cancelButton: "btn font-weight-bold btn-default"
          }
        });
      } else {
        // set the form status
        this.form.post_status = status;

        // dispatch action to save
        this.$store
          .dispatch(SAVE_MESSAGE, { message: this.form, mode: this.saveMode })

          .then(response => {
            // show a toast
            const toast_content = `Message: was saved successfully.`;
            this.toast(toast_content);

            // reroute if creating
            if (this.saveMode == "create") {
              // clear the auto save
              clearInterval(this.autoSaver);

              // set the
              this.form.ID = response.data.ID;
              this.$router.push({
                name: "messages.single",
                params: { id: response.data.ID }
              });
            }
          })

          .catch(error => {
            this.toast("Error | " + error.message);
          })

          .finally(() => {});
      }
    },

    deleteMessage() {
      // check if the user is sure
      Swal.fire({
        text: "Are you sure you want to delete this message?",
        icon: "warning",
        buttonsStyling: false,
        heightAuto: false,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn font-weight-bold btn-light-primary",
          cancelButton: "btn font-weight-bold btn-default"
        }
      }).then(response => {
        if (response.isConfirmed) {
          this.$store
            .dispatch(DELETE_MESSAGE, this.form.ID)

            .then(() => {
              // show toast
              this.toast(`Message Was Successfully Deleted`);

              // redirect to the messages page
              this.$router.push({ name: "messages" });
            })

            .catch(error => {
              this.toast(`Error | ${error.message}`);
            });
        }
      });
    }
  }
};
</script>
